import React, { Component } from 'react';
import styled from "styled-components"

const SVGIcon = styled.svg`
	width:100%;
	height:100%;
`;
const SVGContainer = styled.div`
	display:flex;
	justify-content:center;
	align-items:center;
	width:70px;
	height:70px;
`;
const cons = (

<SVGIcon viewBox="0 0 171 184" >
    
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="consultation" transform="translate(-20.000000, -9.000000)">
            <path d="M53.4249755,149 L189.092405,149 C189.644689,149 190.092405,149.447715 190.092405,150 C190.092405,150.203261 190.030464,150.401697 189.914835,150.568865 L166.397455,184.568865 C166.210701,184.838864 165.903318,185 165.575025,185 L29.9075955,185 C29.3553107,185 28.9075955,184.552285 28.9075955,184 C28.9075955,183.796739 28.9695363,183.598303 29.0851647,183.431135 L52.6025447,149.431135 C52.7892992,149.161136 53.0966823,149 53.4249755,149 Z" id="Rectangle" fill="#D8D8D8"></path>
            <path d="M144.377274,154 L172.466065,154 C173.01835,154 173.466065,154.447715 173.466065,155 C173.466065,155.199104 173.40663,155.393676 173.295371,155.558795 L157.797735,178.558795 C157.611876,178.834628 157.301037,179 156.968429,179 L128.879638,179 C128.327353,179 127.879638,178.552285 127.879638,178 C127.879638,177.800896 127.939073,177.606324 128.050332,177.441205 L143.547968,154.441205 C143.733827,154.165372 144.044666,154 144.377274,154 Z" id="Rectangle" fill="#FE0702"></path>
            <path d="M25.2345213,136 L77.7654787,136 C79.422333,136 80.7654787,137.343146 80.7654787,139 C80.7654787,139.075207 80.7626507,139.150386 80.7570007,139.22538 L77.0653535,188.22538 C76.9474476,189.79037 75.6432568,191 74.0738316,191 L28.9261684,191 C27.3567432,191 26.0525524,189.79037 25.9346465,188.22538 L22.2429993,139.22538 C22.1185251,137.573209 23.3569688,136.132952 25.0091408,136.008478 C25.0841348,136.002828 25.1593147,136 25.2345213,136 Z" id="Rectangle" stroke="#000000" strokeWidth="4" fill="#FE0702"></path>
            <ellipse id="Oval" fill="#FE0702" cx="51.5" cy="107" rx="23.5" ry="24"></ellipse>
            <path d="M135.234521,88 L187.765479,88 C189.422333,88 190.765479,89.3431458 190.765479,91 C190.765479,91.0752065 190.762651,91.1503865 190.757001,91.2253805 L187.065354,140.22538 C186.947448,141.79037 185.643257,143 184.073832,143 L138.926168,143 C137.356743,143 136.052552,141.79037 135.934646,140.22538 L132.242999,91.2253805 C132.118525,89.5732085 133.356969,88.1329522 135.009141,88.008478 C135.084135,88.002828 135.159315,88 135.234521,88 Z" id="Rectangle" fill="#FE0702"></path>
            <g id="Group-2" transform="translate(156.000000, 89.000000)" fill="#D8D8D8">
                <polygon id="Rectangle" points="0 0 11 0 8.62566958 7 2.37433042 7"></polygon>
                <polygon id="Rectangle" transform="translate(5.500000, 21.500000) scale(1, -1) translate(-5.500000, -21.500000) " points="0 8 11 8 8.62566958 35 2.37433042 35"></polygon>
                <polygon id="Rectangle" transform="translate(5.861929, 35.949153) rotate(45.000000) translate(-5.861929, -35.949153) " points="1.59074237 31.6779661 9.86192881 31.9491525 10.1331153 40.220339 1.86192881 39.9491525"></polygon>
            </g>
            <ellipse id="Oval" fill="#FE0702" cx="161.5" cy="59" rx="23.5" ry="24"></ellipse>
            <rect id="Rectangle" fill="#FE0702" x="43" y="34" width="57" height="37" rx="3"></rect>
            <rect id="Rectangle" stroke="#000000" strokeWidth="3" fill="#D8D8D8" x="77" y="11" width="57" height="37" rx="3"></rect>
            <polygon id="Triangle" fill="#D8D8D8" transform="translate(126.500000, 48.500000) scale(1, -1) rotate(28.000000) translate(-126.500000, -48.500000) " points="126.5 41 134 56 119 56"></polygon>
            <polygon id="Triangle" fill="#FE0702" transform="translate(50.500000, 73.500000) scale(-1, -1) rotate(28.000000) translate(-50.500000, -73.500000) " points="50.5 66 58 81 43 81"></polygon>
        </g>
    </g>
</SVGIcon>
)

const feas = (
<SVGIcon viewBox="0 0 193 163" >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="feasibility" transform="translate(-4.000000, -26.000000)">
            <g id="Group" transform="translate(133.000000, 96.000000)" stroke="#FE0702" strokeLinecap="round" strokeWidth="17">
                <line x1="0.720588235" y1="3.46551724" x2="16.25" y2="30" id="Line"></line>
                <line x1="19.733871" y1="30.530303" x2="49.0080645" y2="0.704545455" id="Line-2"></line>
            </g>
            <g id="Group" transform="translate(43.500000, 111.500000) scale(-1, 1) translate(-43.500000, -111.500000) translate(19.000000, 96.000000)" stroke="#FE0702" strokeLinecap="round" strokeWidth="17">
                <line x1="0.220588235" y1="3.46551724" x2="15.75" y2="30" id="Line"></line>
                <line x1="19.233871" y1="30.530303" x2="48.5080645" y2="0.704545455" id="Line-2"></line>
            </g>
            <path d="M67.158788,91 L132.841212,91 C134.498066,91 135.841212,92.3431458 135.841212,94 C135.841212,94.0515561 135.839883,94.1031037 135.837226,94.1545913 L131.090119,186.154591 C131.007837,187.749243 129.690878,189 128.094105,189 L71.9058951,189 C70.3091218,189 68.9921632,187.749243 68.9098808,186.154591 L64.1627737,94.1545913 C64.0773953,92.4999383 65.3495437,91.0893642 67.0041967,91.0039857 C67.0556843,91.001329 67.1072319,91 67.158788,91 Z" id="Rectangle" fill="#FE0702"></path>
            <circle id="Oval" fill="#FE0702" cx="100" cy="55" r="29"></circle>
            <g id="Group-2" transform="translate(93.000000, 92.000000)" fill="#D8D8D8">
                <polygon id="Rectangle" points="0.25 0 14.25 0 11.2281249 9 3.27187508 9"></polygon>
                <polygon id="Rectangle" transform="translate(7.250000, 26.500000) scale(1, -1) translate(-7.250000, -26.500000) " points="0.25 10 14.25 10 11.2281249 43 3.27187508 43"></polygon>
                <polygon id="Rectangle" transform="translate(7.327411, 43.652542) rotate(45.000000) translate(-7.327411, -43.652542) " points="2.14097034 38.4661017 12.327411 38.6525424 12.5138517 48.8389831 2.32741102 48.6525424"></polygon>
            </g>
            <rect id="Rectangle" fill="#D8D8D8" x="4" y="76" width="34" height="8"></rect>
            <rect id="Rectangle" fill="#D8D8D8" x="7" y="68" width="28" height="8"></rect>
            <rect id="Rectangle" fill="#D8D8D8" x="163" y="76" width="34" height="8"></rect>
            <rect id="Rectangle" fill="#D8D8D8" x="166" y="68" width="28" height="8"></rect>
        </g>
    </g>
</SVGIcon>
)
const plan = (
	<SVGIcon  viewBox="0 0 169 177" >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="planning" transform="translate(-18.000000, -12.000000)" fillRule="nonzero">
            <g id="document" transform="translate(18.000000, 12.000000)" fill="#FE0702">
                <path d="M18.2608696,157.666667 L122.956522,157.666667 L122.956522,52.25 L88.057971,52.25 L88.057971,17.1111111 L18.2608696,17.1111111 L18.2608696,157.666667 Z M0,167.2 L0,0 L105,0 L140,35.2 L140,176 L0,176 L0,167.2 Z" id="Shape"></path>
            </g>
            <g id="home" transform="translate(45.000000, 68.000000)" fill="#D8D8D8">
                <polygon id="Path" points="34.8 94 13.05 94 13.05 47 0 47 43.5 0 87 47 73.95 47 73.95 94 52.2 94 52.2 65.8 34.8 65.8"></polygon>
            </g>
            <g id="edit-pencil" transform="translate(177.000000, 151.000000) rotate(135.000000) translate(-177.000000, -151.000000) translate(144.000000, 118.000000)" fill="#FE0702">
                <path d="M40.7484663,12 L54,25.2515337 L13.2515337,66 L0,66 L0,52.7484663 L40.7484663,12 Z M45,7.66666667 L52.6666667,0 L66,13.3333333 L58.3333333,21 L45,7.66666667 L45,7.66666667 Z" id="Shape"></path>
            </g>
        </g>
    </g>

	</SVGIcon>
	)
const cond = (
<SVGIcon  viewBox="0 0 141 193" >
	<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="conditions" transform="translate(-22.000000, -5.000000)">
					<rect id="Rectangle" stroke="#FE0702" strokeWidth="7" x="26" y="9" width="133" height="162" rx="2"></rect>
					<rect id="Rectangle" fill="#000000" x="48" y="136" width="89" height="62"></rect>
					<rect id="Rectangle" fill="#D8D8D8" x="68" y="120" width="44" height="46"></rect>
					<g id="shield" transform="translate(57.000000, 100.000000)" fill="#FE0702" fillRule="nonzero">
							<path d="M72,44 C71.4658806,53.7227122 66.2461182,62.5813948 58,67.76 L36,80 L14,67.76 C5.75388183,62.5813948 0.534119402,53.7227122 0,44 L0,12 C13.52,12 26,7.52 36,0 C46,7.56 58.48,12 72,12 L72,44 Z M36,47.84 L47.7293869,56 L43.5919662,42.36 L55,33.76 L40.6997886,33.44 L36,20 L31.3002114,33.44 L17,33.76 L28.3678647,42.32 L24.230444,55.92 L36,47.88 L36,47.84 Z" id="Shape"></path>
					</g>
					<line x1="48.5" y1="31.5" x2="137.5" y2="31.5" id="Line-3" stroke="#FE0702" strokeWidth="7" strokeLinecap="round"></line>
					<line x1="48.5" y1="51.5" x2="137.5" y2="51.5" id="Line-3" stroke="#FE0702" strokeWidth="7" strokeLinecap="round"></line>
					<line x1="48.5" y1="71.5" x2="107.5" y2="71.5" id="Line-3" stroke="#FE0702" strokeWidth="7" strokeLinecap="round"></line>
			</g>
	</g>
</SVGIcon>
)

const breg = (
	<SVGIcon viewBox="0 0 162 176">

    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="building-regulations" transform="translate(-19.000000, -12.000000)" fillRule="nonzero">
            <g id="home" transform="translate(19.000000, 12.000000)" fill="#FE0702">
                <polygon id="Path" points="64.8 176 24.3 176 24.3 88 0 88 81 0 162 88 137.7 88 137.7 176 97.2 176 97.2 123.2 64.8 123.2"></polygon>
            </g>
            <g id="checkmark" transform="translate(79.000000, 41.000000)" fill="#D8D8D8">
                <polygon id="Path" points="0 17.0666667 4.3 12.8 15.05 23.4666667 38.7 0 43 4.26666667 15.05 32"></polygon>
            </g>
            <g id="checkmark" transform="translate(79.000000, 66.000000)" fill="#D8D8D8">
                <polygon id="Path" points="0 17.0666667 4.3 12.8 15.05 23.4666667 38.7 0 43 4.26666667 15.05 32"></polygon>
            </g>
            <g id="checkmark" transform="translate(79.000000, 92.000000)" fill="#D8D8D8">
                <polygon id="Path" points="0 17.0666667 4.3 12.8 15.05 23.4666667 38.7 0 43 4.26666667 15.05 32"></polygon>
            </g>
        </g>
    </g>

	</SVGIcon>
)

const asvs = (
	<SVGIcon viewBox="0 0 184 131">
	
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="additional-services" transform="translate(-14.000000, -30.000000)">
            <rect id="Rectangle" fill="#D8D8D8" x="14" y="30" width="153" height="27" rx="7"></rect>
            <circle id="Oval" fill="#000000" cx="33" cy="43" r="8"></circle>
            <rect id="Rectangle" fill="#D8D8D8" x="14" y="70" width="153" height="27" rx="7"></rect>
            <circle id="Oval" fill="#000000" cx="33" cy="83" r="8"></circle>
            <rect id="Rectangle" fill="#D8D8D8" x="14" y="110" width="153" height="27" rx="7"></rect>
            <circle id="Oval" fill="#000000" cx="33" cy="123" r="8"></circle>
            <rect id="Rectangle" fill="#000000" x="140" y="103" width="41" height="43"></rect>
            <g id="add-solid" transform="translate(124.000000, 87.000000)" fill="#FE0702" fillRule="nonzero">
                <path d="M40.7,33.3 L40.7,18.5 L33.3,18.5 L33.3,33.3 L18.5,33.3 L18.5,40.7 L33.3,40.7 L33.3,55.5 L40.7,55.5 L40.7,40.7 L55.5,40.7 L55.5,33.3 L40.7,33.3 Z M37,74 C16.5654643,74 0,57.4345357 0,37 C0,16.5654643 16.5654643,0 37,0 C57.4345357,0 74,16.5654643 74,37 C74,57.4345357 57.4345357,74 37,74 Z" id="Shape"></path>
            </g>
        </g>
    </g>
	</SVGIcon>
)
const strt = (
<SVGIcon viewBox="0 0 162 176">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="start" transform="translate(-19.000000, -12.000000)">
            <g id="home" transform="translate(19.000000, 12.000000)" fill="#FE0702" fillRule="nonzero">
                <polygon id="Path" points="64.8 176 24.3 176 24.3 88 0 88 81 0 162 88 137.7 88 137.7 176 97.2 176 97.2 123.2 64.8 123.2"></polygon>
            </g>
            <g id="arrow-outline-right" transform="translate(57.000000, 45.000000)">
                <circle id="Oval" fill="#FE0702" cx="41" cy="41" r="36"></circle>
                <path d="M86,43 C86,66.7482442 66.7482442,86 43,86 C19.2517558,86 0,66.7482442 0,43 C0,19.2517558 19.2517558,0 43,0 C66.7482442,0 86,19.2517558 86,43 Z M77.2753623,43 C77.2753623,24.0702401 61.9297599,8.72463768 43,8.72463768 C24.0702401,8.72463768 8.72463768,24.0702401 8.72463768,43 C8.72463768,61.9297599 24.0702401,77.2753623 43,77.2753623 C61.9297599,77.2753623 77.2753623,61.9297599 77.2753623,43 Z M43,51.7246377 L21.1884058,51.7246377 L21.1884058,34.2753623 L43,34.2753623 L43,21.1884058 L64.8115942,43 L43,64.8115942 L43,51.7246377 Z" id="Shape" fill="#D8D8D8" fillRule="nonzero"></path>
            </g>
        </g>
    </g>
</SVGIcon>
	)

const comp = (
	<SVGIcon viewBox="0 0 141 195">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="complete" transform="translate(-22.000000, -5.000000)">
            <rect id="Rectangle" stroke="#FE0702" strokeWidth="7" x="26" y="9" width="133" height="162" rx="2"></rect>
            <rect id="Rectangle" fill="#000000" x="48" y="136" width="89" height="62"></rect>
            <g id="Group" transform="translate(70.000000, 138.000000)">
                <polygon id="Rectangle" fill="#FE0702" transform="translate(14.265639, 29.673240) rotate(12.000000) translate(-14.265639, -29.673240) " points="6.83647806 1.23189525 21.9869966 1.15592433 21.6948007 58.1145842 6.5442822 58.1905551"></polygon>
                <polygon id="Rectangle" fill="#000000" transform="translate(8.080267, 58.056385) rotate(57.000000) translate(-8.080267, -58.056385) " points="2.98028484 52.9038077 13.2983792 52.7856769 13.1802484 63.2089618 2.86215396 63.3270927"></polygon>
            </g>
            <g id="Group" transform="translate(87.000000, 138.000000)">
                <polygon id="Rectangle" fill="#FE0702" transform="translate(13.734361, 29.673240) rotate(-12.000000) translate(-13.734361, -29.673240) " points="6.01300339 1.15592433 21.1635219 1.23189525 21.4557178 58.1905551 6.30519925 58.1145842"></polygon>
                <polygon id="Rectangle" fill="#000000" transform="translate(19.919733, 58.056385) rotate(33.000000) translate(-19.919733, -58.056385) " points="14.7671564 52.956403 25.1904413 52.8382721 25.0723104 63.1563665 14.6490255 63.2744974"></polygon>
            </g>
            <polygon id="Star" fill="#FE0702" points="92 170.496419 81.0964742 176.771468 73.9953784 166.386982 61.4489997 167.309743 59.556793 154.872594 47.8525255 150.260303 51.5439826 138.233822 43 129 51.5439826 119.766178 47.8525255 107.739697 59.556793 103.127406 61.4489997 90.6902574 73.9953784 91.6130181 81.0964742 81.2285323 92 87.5035807 102.903526 81.2285323 110.004622 91.6130181 122.551 90.6902574 124.443207 103.127406 136.147475 107.739697 132.456017 119.766178 141 129 132.456017 138.233822 136.147475 150.260303 124.443207 154.872594 122.551 167.309743 110.004622 166.386982 102.903526 176.771468"></polygon>
            <g id="checkmark" transform="translate(77.000000, 118.000000)" fill="#DDDDDD" fillRule="nonzero">
                <polygon id="Path" points="0 11.7333333 3 8.8 10.5 16.1333333 27 0 30 2.93333333 10.5 22"></polygon>
            </g>
            <ellipse id="Oval" stroke="#DDDDDD" strokeWidth="4" cx="92.0089286" cy="128.5" rx="27.0089286" ry="27.5"></ellipse>
            <line x1="48.5" y1="31.5" x2="137.5" y2="31.5" id="Line-3" stroke="#FE0702" strokeWidth="7" strokeLinecap="round"></line>
            <line x1="48.5" y1="51.5" x2="137.5" y2="51.5" id="Line-3" stroke="#FE0702" strokeWidth="7" strokeLinecap="round"></line>
            <line x1="48.5" y1="71.5" x2="107.5" y2="71.5" id="Line-3" stroke="#FE0702" strokeWidth="7" strokeLinecap="round"></line>
        </g>
    </g>
	</SVGIcon>
)
function getIcon(iconCode){
	switch(iconCode){
		case "cons":
			return cons;
		case "feas":
			return feas;
		case "plan":
			return plan;
		case "cond":
			return cond;
		case "breg":
			return breg;
		case "asvs":
			return asvs;
		case "strt":
			return strt;
		case "comp":
			return comp;
		default:
			return null;
	}
}
class ServiceIcons extends Component {


	toggleSideDrawer(){
		const flag = this.state.isOpened;
		this.setState({ isOpened: !flag })
	}
	render() {

		const icon = getIcon(this.props.iconCode)
		return (
			<SVGContainer>
				{icon}
			</SVGContainer>
		);
	}
}






export default ServiceIcons
